@import '_colors';
@import '_typo';

.card {
    box-shadow: $shadow_primary-g;
    min-width: 200px;
    border-radius: 10px;
    overflow: hidden;
    border: none;

    .card-header {
        background-color: $color_secondary-red-dark;
        color: #FFFFFF;
        font-size: 1.25em;
    }

    .card-body {
        padding: 5%;
        line-height: 1.35rem;

        .card-text {
            font-size: 1em;   
            line-height: 1.5em;
            text-align: left;
        }

        .list-group-item:first-child{
            border-top-color: $color_primary-r;
            border-top-style: solid;
            border-top-width: 1px;
            padding: 0px;
        }

        .list-group-item {
            border-color: $color_primary-r;
            padding: 0;
            margin-top: .5rem;
            border-bottom: none;
            align-content: center;

            .list-group-item-header {
                display: block;
                margin-left: auto;
                margin-right: auto;
                text-transform: uppercase;
                color: #FFFFFF;
                font-weight: normal;
                font-size: 0.9rem;
                background-color: $color_primary-r;
                width: 40%;
                min-width: 125px;
                padding-left: 10px;
                padding-right: 10px;
            }

            p {
                margin-bottom: 0;
                font-size: 0.8rem;
            }
        }
    }
}