@import "_breakpoints";
@import "_colors.scss";
@import "_typo.scss";

.hero {
    background-color: $color-primary-g-light
}

.hero-title {
    font-size: 4em;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 300;
    color: $color-primary-g;
    margin-top: .5em;

    @include for-phone-only {
        font-size: 2.5em;
    }
}

.hero-content {
    font-size: 1.25em;
    font-weight: 300;
    color: $color-primary-g;
    margin-bottom: .25em;
    padding-bottom: 1em;
    text-align: center;

    @include for-phone-only {
        font-size: 1.1em;
        width: 80%;
        margin: auto;
    }
}

.hero-fs-image {
    padding-top: 5%;
}

.hero-fs-content {
    background-color: $color-primary-g;
    color: #FFFFFF;
    text-align: left;
    
    p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        font-size: 1.3em;

        @include for-phone-only {
            font-size: 1em;
            width: 90%;
            padding: 5px;
        }
    }
}

.hero-fs-logo {
    
    display: inline-block;
    position: relative;
    background: #FFFFFF;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 225px;
    height: 225px;
    align-content: center;
    align-items: center;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    box-shadow: 0 4px 5px 0 rgba(37, 153, 113, 0.1), 0 6px 10px 0 rgba(37, 153, 113, 0.1);
    margin: auto; 

    @include for-phone-only {
        top: 55%;
        width: 180px;
        height: 180px;
    }

    img {
        max-height: 170px;
        position: absolute;
        height: 70%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .square {
        width: 50%;
        height: 50%;
        background-color: #FFFFFF;
        position: absolute;
        overflow: visible
    }
}

.hero-fs-slogan {
    text-align: center;
    color: $color-primary-g;
    font-size: 2.25em;
    font-weight: $font-bold;
    margin: auto;
    width: 80%;
    max-width: 300px;

    @include for-phone-only {
        font-size: 2em;
        margin-top: .75em;
    }
}
