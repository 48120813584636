@import 'theme/_buttons';
@import 'theme/_cards';
@import 'theme/_colors';
@import 'theme/_course';
@import 'theme/_forms';
@import 'theme/_hero';
@import 'theme/_images';
@import 'theme/_menu';
@import 'theme/_typo';

html, body {
    font-family: 'Mitr', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: $font-light;
    font-size: 16px;

    ul {
        padding-inline-start: 0em !important;
    }

    select:not([multiple]) {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-position: right 50%;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
        padding: .5em;
        padding-right: 1.5em
    }
}

.container {
    padding: 1em;

    &.fullscreen {
        padding: 0px;
        height: -webkit-calc(100vh - 40px);
        height:    -moz-calc(100vh - 40px);
        height:         calc(100vh - 40px);
        max-height: 1024px;
        min-height: 667px;
    }
}

section {
    &.red {
        background-color: #FFFFFF;
        border-style: solid;
        border-width: 2px;
        border-top: none;
        border-color: $color_secondary-red-dark;
    }
}

p {
    line-height: 1.5em;
    font-weight: $font-light
}

.row {
    margin-left: -15px;
    margin-right: -15px;
}

.text {

    padding: 2em;

    @include for-phone-only {
        padding: 1em;
    }

    @include for-tablet-landscape-up {
        padding: 2.5em;
    }

    p {
        padding-bottom: .75em;
    }

    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        
        li {
            padding-left: 2em;
            padding-bottom: .75em;
            text-indent: -2em;
            line-height: 1.75em;
            font-weight: $font-light;
        
            &:last-child { 
                padding-bottom: none; 
            }

            &:before {
                content: '';
                display: inline-block;
                height: .8em;
                width: 0.8em;
                background-image: url("sle-bullet-point.svg");
                background-size: contain;
                background-repeat: no-repeat;
                padding-left: 1.2em;
                padding-right: 0.7em;
            }
        }
    }
}
