@import '_colors';
@import '_typo';

.img-col {
    position: relative;
    //display: inline;
    overflow: hidden;
}

.img-lbl {
    position: absolute;
    text-align: center;
    font-size: 1em;
    color: #FFFFFF;
    background-color: $color_primary-g;
    width: 250px;
    line-height: 1em;
    height: 50px;
    bottom: 2em;
    right: 2em;

    padding: 2px 2px 2px 2px;

    .img-lbl-title {
        font-size: 0.9rem;
        font-weight: normal;
        text-transform: uppercase;
        line-height: 15px;
        text-align: center;
        margin-top: 5px;
        color: #FFFFFF;
    }

    .img-lbl-subtitle {
        font-size: 0.9rem;
        font-weight: normal;
        line-height: .5rem;
        text-align: center;
        margin-bottom: 0px;
        color: #FFFFFF;
    }
}