@import "_colors.scss";
@import "_typo.scss";

.form-container {
    background: $color_primary-g-dark;
    padding: 1em;
}

.form-content {
    color: #FFFFFF;
    padding: 1em;

    p {
        line-height: 2em;
    }

    @include for-phone-only {
        padding: 0em;
    }
}

.form-label {
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1em;
    background-color: $color_primary-g;
    width: 100%;
    //height: 38px;
    //line-height: 38px; 
    height: 100%;
    line-height: 200%;
    text-align: left;
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;

    .inline {
        margin: 0em;
        margin-top: 0.2em;
    }
}

.form-control {
    //appearance: none;
    background-color: $color_primary-g-light;
    border: none;
    border-radius: 0px;
    font-size: 1.1em;
}

.form-control:focus {
    background-color: $color_primary-g-light !important;
    border-color: $color_primary-r !important;
    box-shadow: $shadow_black !important;
}
