@import '_colors';
@import '_typo';

$menu-height: 40px;

.navbar {
    background-color: #FFFFFF;
    height: 100%;
    padding: 0em;

    .nav-link {
        color: $color-primary-g;
        text-transform: uppercase;
        font-weight: $font-normal;

        &:hover {
            color: $color-primary-g;
            background-color: $color-primary-g-light;
            text-decoration: underline;
        }

        &.active {
            color: $color-primary-g;
            background-color: $color-primary-g-light;
        }
    }

    .nav-item {
        
        button {
            border: none;
            border-radius: 0em;
        }
    }
}

.navbar-collapse {

    background-color: #FFFFFF;
    z-index: 99999999;
    width: 400px;
    text-align: center;

    &.show {
        background-color: #FFFFFF;
        width: 500px;
    }

    .dropdown-toggle {
        width: 100%;
        background-color: #FFFFFF;        
    }
}

.navbar-toggler {
    color: $color_primary-g;
    border-style: none;
    border-radius: 0px;
    padding-right: 1em;

    &i {
        color: $color_primary-g;
    }
}

.dropdown-menu {
    background: #FFFFFF;
    border: none;
    border-radius: 0em;
    margin: 0em;
    padding: 0em;

    li:last-child {
        border-bottom: 1px solid $color-primary-g;
    }

    .nav-link {
        border-top: 1px solid $color-primary-g;
        text-align: center;
    }
}

.navbar-logo {
    padding-left: 1em;
}