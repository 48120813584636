/*
    Define a reasonable set of custom breakpoints as
    discussed in: https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
*/

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}

@mixin for-phone-landscape {
    @media (max-width: 768px) and (max-height: 400px) { @content; }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}

@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

@mixin mobile-menu {
    @media (max-width: 768px) { @content; }
}