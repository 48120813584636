// COLORS
// ==================

$color_primary-g: #259a71;
$color_primary-g-dark: #003732;
$color_primary-g-light: #CEE0D2;
$color_primary-r: #FF8275;
$color_secondary-g: #7FB82B;
$color_secondary-red-dark: #C83148;
$color_secondary-orange: #D44C02;


// HEADER BACKGROUNDS
// ==================

$header_bg: #ffffff;
$header_color: $color_primary-g;
$panel_bg: #ffffff;

// SHADOWS
// =======

$shadow_primary-g: 0 4px 5px 0 rgba(37, 153, 113, 0.1), 0 6px 10px 0 rgba(37, 153, 113, 0.1);
$shadow_black: 0 1px 5px rgba(0, 0, 0, 0.46);

// TEXT COLOR CLASSES
// ==================

.text-primary-g {
    color: $color_primary-g;
}

.text-primary-g-dark {
    color: $color_primary-g-dark;
}

.text-primary-g-light {
    color: $color_primary-g-light;
}

.text-primary-r {
    color: $color_primary-r;
}

.text-secondary-red-dark {
    color: $color_secondary-red-dark;
}

.text-secondary-orange {
    color: $color_secondary-orange;
}

// BACKGROUND COLOR CLASSES
// ========================

.bg-primary-g {
    background-color: $color_primary-g;
}

.bg-primary-g-dark {
    background-color: $color_primary-g-dark;
}

.bg-primary-g-light {
    background-color: $color_primary-g-light;
}

.bg-primary-r {
    background-color: $color_primary-r;
}

.bg-secondary-red-dark {
    background-color: $color_secondary-red-dark;
}

.bg-secondary-orange {
    background-color: $color_secondary-orange;
}
