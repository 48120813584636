@import "_colors.scss";
@import "_breakpoints.scss";

/*
    FONTS
*/
$font-light: 300;
$font-normal: 400;
$font-bold: 500;

/*
    FONT SIZES & COLORS
*/

h1 {
    font-size: 4em;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 300;
    color: $color-primary-g-dark;
    margin-top: .5em;
    margin-bottom: .5em;

    @include for-phone-only {
        font-size: 3em;
    }
}

h2 {
    color: $color_primary-g;
    font-weight: $font-bold;
    padding-bottom: .5em;
}

h3 {
    color: $color_primary-g;
    font-weight: $font-normal;
    padding-bottom: .25em;
}

h4 {
    color: $color_primary-g;
    font-weight: $font-normal;
    padding-bottom: .1em;
    font-size: 1.1em;
}

p {
    margin-bottom: .25em;
    font-weight: $font-light;
}

strong {
    font-weight: $font-normal
}

.text-light {
    font-weight: $font-light
}

.text-single-space {
    line-height: 1em;
}

.text-half-space {
    line-height: 1.5em;
}

.text-double-space {
    line-height: 2em !important;
}

.text {

    p {
        text-align: left;
    }
}
