@import '_colors';
@import '_typo';

/*
    Removes blue outline added by browsers
*/ 
button:focus {
    outline: 0;
}

.btn-sle {
    color: #FFFFFF;
    background: $color_secondary-red-dark;
    text-transform: uppercase;
    min-width: 200px;
    border: none;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;

    &:hover {
        color: $color_secondary-red-dark;
        background: #FFFFFF;
        border: 1px solid; 
        border-color: $color_secondary-red-dark;
    }
}

.btn-sle::before {
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
}

.btn-clear {
    font-weight: $font-light;
    color: $color_primary-g;
    background: none;
    border: none;
    min-width: 40px;
    letter-spacing: 0.1em;

    &:hover {
        color: $color_secondary-red-dark;
    }
}

.btn-square {
    color: $color_secondary-red-dark;
    background-color: #FFFFFF;
    box-shadow: $shadow_primary-g;
    font-size: 1.15em;
    padding: 5px;
    min-width: 100px;
    width: 100%;
    height:80px;
    /*
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    */

    &.collapsed {
        color: #FFFFFF;
        background-color: $color_secondary-red-dark;
        //background-color: #8A0829;
        box-shadow: none;
    }

    &.invert {
        background-color: $color_secondary-red-dark;
        border: none;
        color: #FFFFFF;
    }

    &:hover {
        color: $color_secondary-red-dark;
        background-color: #FFFFFF;
        box-shadow: $shadow_primary-g;
        border: none;
    }
}

.btn-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    font-size: 1.5em;
    color: #fff;
    line-height: 190px;
    text-align: center;
    background: $color_secondary-red-dark;

    &:hover {
        color: $color_secondary-red-dark;
        background-color: #FFFFFF;
        box-shadow: $shadow_primary-g;
        border: none;
    }

    @include for-phone-only {
        width: 135px;
        height: 135px;
        border-radius: 50%;
        font-size: 1em;
        line-height: 125px;
    }
  }